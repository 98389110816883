<template>
  <div>

    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Código Postal')"
        >
          <p
            class="text-primary mb-0"
          >
            {{ form_data.arrayAgencia172.sw172s07 || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Distrito')"
        >
          <p
            class="text-primary mb-0"
          >
            {{ form_data.arrayAgencia172.sw172s04 || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Concelho')"
        >
          <p
            class="text-primary mb-0"
          >
            {{ form_data.arrayAgencia172.sw172s05 || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Freguesia')"
        >
          <p
            class="text-primary mb-0"
          >
            {{ form_data.arrayAgencia172.sw172s06 || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Morada')"
        >
          <p
            class="text-primary mb-0"
          >
            {{ form_data.arrayAgencia172.sw172s03 || '-----' }}
          </p>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { mask } from 'vue-the-mask'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showMsgRequest } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
    mask,
  },
  mixins: [showMsgRequest],
  props: {
    fillMapsLatLong: {
      type: Function,
      default: () => {},
    },
    view: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('crm_visitas_form', ['form_data']),
  },
}
</script>
